<template>
<div>
  <b-container fluid>
    <b-row class="mb-4" v-if="!permissions.workflow">
      <b-col>
        Access only for RRIC Admins
      </b-col>
    </b-row>
    <b-row class="mb-4" v-if="permissions.workflow">
      <b-col>
        <b-container>
          <b-row class="mb-4">
            <b-col>
              <menu-workflow :activekey="'starrednews'" />
            </b-col>
          </b-row>
          <b-row class="mb-4" v-if="!firstRun">
            <b-col>
              <b-card class="mb-3">
                <router-link class="mr-2 btn btn-outline-primary" :to="{ name: 'NewsAdvancedFilter' }">Filter</router-link>
              </b-card>
              <b-card class="mb-3" v-for="item in news" :key="item.id" :id="item.id">
                <publication
                  :propItem="item"
                  :key="item.id"
                  :tagsProp=tags
                  :username="user.username"
                  :permissionAddArticle="permissionAddArticle"
                  :permissionAddRisk="permissionAddRisk"
                  :permissionAddTag="permissionAddTag"
                  :permissionArticlesWip="permissionArticlesWip"
                  :permissionDeletePublication="permissionDeletePublication"
                  :permissionInsights="permissionInsights"
                  :permissionReadTagsPanel="permissionReadTagsPanel"
                  />
              </b-card>
            </b-col>
          </b-row>
          <b-row class="text-center mb-4" align-h="center" v-if="firstRun">
            <b-col cols="1">
              <div><i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i></div>
            </b-col>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
  </b-container>
</div>
</template>

<script>
import ac from '@/libs/accesscontrol'
import Publication from '@/components/PublicationNew'
import MenuWorkflow from '@/components/workflow/article/Menu.vue'

export default {
  components: {
    MenuWorkflow,
    Publication
  },
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: async function () {
    this.$stat.log({ page: 'news', action: 'open news' })
    this.permissions.workflow = ac.can(this.user.acgroups).readAny('workflow').granted
    this.permissionAddArticle = ac.can(this.user.acgroups).createAny('article').granted
    this.permissionAddRisk = true
    this.permissionAddTag = ac.can(this.user.acgroups).createAny('newstag').granted
    this.permissionArticlesWip = ac.can(this.user.acgroups).createAny('articleWorkflowEdit').granted
    this.permissionDeletePublication = ac.can(this.user.acgroups).deleteAny('publication').granted
    this.permissionInsights = true
    this.permissionReadAnalytics = ac.can(this.user.acgroups).readAny('analytics').granted
    this.permissionReadTagsPanel = ac.can(this.user.acgroups).readAny('tagspanel').granted
    try {
      let apiName = 'cosmos'
      let path = `/tags`
      this.tags = await this.$Amplify.API.get(apiName, path)
    } catch (e) {
      this.$logger.warn('error getting events for a day', e)
    }
    this.load()
  },
  data () {
    return {
      authToken: '',
      firstRun: true,
      formEventValidated: false,
      gridLoaderColor: 'black',
      gridLoaderSize: '10px',
      item: {},
      limit: 20,
      loadingNews: true,
      news: [],
      offset: 0,
      page: 0,
      permissions: {},
      permissionAddArticle: false,
      permissionAddRisk: false,
      permissionAddTag: false,
      permissionArticlesWip: false,
      permissionDeletePublication: false,
      permissionReadAnalytics: false,
      permissionReadTagsPanel: false,
      tags: []
    }
  },
  methods: {
    load: async function () {
      this.$logger.debug('load started')
      this.offset = this.page * this.limit
      try {
        let apiName = 'cosmos'
        let path = `/news/starred/ric`
        this.news = await this.$Amplify.API.get(apiName, path)
        this.firstRun = false
        this.loadingNews = false
      } catch (e) {
        this.$logger.warn('saved ERROR: ' + e)
      }
    }
  }
}
</script>

<style>
.export-table table {
 border: solid;
}
</style>
